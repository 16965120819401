import React, { Component } from 'react';
import { getMonthNumbers, getYearsFromCurrent, onlyNumbers } from '../helpers/app';
import { checkCard } from '../api/client';

// Import components
import { Field, reduxForm } from 'redux-form';
import PayixButton from '../containers/payix-button';

let monthNumbers = getMonthNumbers();
let years = getYearsFromCurrent();

const getMonthIndex = function() {
  let date = new Date();

  return date.getMonth();
}

const validate = (values, _parent) => {
  const errors = {};

  if (!values.name) {
    errors.name = _parent.whiteLabel.labels['This field is required!'];
  }
  
  if (!values.cardNumber) {
    errors.cardNumber = _parent.whiteLabel.labels['This field is required!'];
  }

  if (!values.expirationMonth) {
    errors.expirationMonth = _parent.whiteLabel.labels['This field is required!'];
  }

  if (!values.expirationYear) {
    errors.expirationYear = _parent.whiteLabel.labels['This field is required!'];
  }

  if (!values.cvvNumber) {
    errors.cvvNumber = _parent.whiteLabel.labels['This field is required!'];
  }

  if (values.cvvNumber && values.cvvNumber.length < 3) {
    errors.cvvNumber = _parent.whiteLabel.labels['CVC should be 3 numbers.'];
  }

  if (!values.billingZip) {
    errors.billingZip = _parent.whiteLabel.labels['This field is required!'];
  }

  if (values.billingZip && values.billingZip.length > 0 && values.billingZip.length < 5) {
    errors.billingZip = _parent.whiteLabel.labels['Zip code should be 5 numbers'];
  }

  return errors;
};

const asyncValidate = (values) => {
  return checkCard(values.cardNumber)
    .then(response => {
      if (response.data.statusCode === 201) {
        let errors = {
          cardNumber: response.data.message
        };

        throw errors;
      }
    })
}

const renderField = ({input, type, name, maxLength, meta: { dirty, active, touched, error }}) => (
  <div>
    <input className="form-group__input" id={name} {...input} maxLength={maxLength} type={type} />
    { (dirty || active || touched) && error && <span className="form-group__error">{error}</span> }
  </div>
)

const renderSelect = ({input, name, meta: { dirty, active, touched, error }, children}) => (
  <div>
    <select className="form-group__input" id={name} {...input}>
      {children}
    </select>
    { (dirty || active || touched) && error && <span className="form-group__error">{error}</span> }
  </div>
)

class AddCardForm extends Component {
  constructor(props) {
    super(props);
    
    this.month = getMonthIndex() - 1;
  }

  /**
   * Render the component view.
   */
  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <div className="form-group">
          <label className="form-group__label" htmlFor="name">{this.props.whiteLabel.labels['Name']}</label>
          <Field name="name" type="text" maxLength="30" component={renderField} />
        </div>

        <div className="form-group">
          <label className="form-group__label" htmlFor="cardNumber">{this.props.whiteLabel.labels['Card Number']}</label>
          <Field name="cardNumber" type="text" maxLength="16" component={renderField} normalize={onlyNumbers} />
        </div>

        <div className="form-group">
          <label className="form-group__label" htmlFor="number">{this.props.whiteLabel.labels['Expiration Date']}</label>
          <div className="flex-block">
            <div className="form-group size-50per margin-right-10">
              <Field name="expirationMonth" component={renderSelect}>
                <option key="1" value="">
                {this.props.whiteLabel.labels['Month']}
                </option>

                {monthNumbers.map((month, index) => 
                  <option key={month} value={month}>
                    {month}
                  </option>
                )}
              </Field>
            </div>

            <div className="form-group size-50per margin-left-10">
              <Field name="expirationYear" component={renderSelect}>
                <option key="1" value="">
                {this.props.whiteLabel.labels['Year']}
                </option>

                {years.map(year => 
                  <option key={year} value={year}>
                    {year}
                  </option>
                )}
              </Field>
            </div>
          </div>
        </div>

        <div className="flex-block">
          <div className="form-group margin-right-10 size-50per">
            <label className="form-group__label" htmlFor="cvvNumber">{this.props.whiteLabel.labels['CVC']}</label>
            <Field name="cvvNumber" type="text" maxLength="4" component={renderField} normalize={onlyNumbers} />
          </div>

          <div className="form-group margin-left-10 size-50per">
            <label className="form-group__label" htmlFor="billingZip">{this.props.whiteLabel.labels['Billing Zip']}</label>
            <Field name="billingZip" type="text" maxLength="5" component={renderField} normalize={onlyNumbers} />
          </div>
        </div>

        <PayixButton className="button--full" type="submit" disabled={!this.props.valid}>
            {this.props.whiteLabel.labels['Save']}
        </PayixButton>
      </form>
    );
  }
}

AddCardForm = reduxForm({
  form: 'addCardForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['cardNumber']
})(AddCardForm)
 
export default AddCardForm;