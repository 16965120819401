import React, { Component } from 'react';
import { Application } from '../core/app';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoanAccountService from '../services/loan-account';
import { withRouter } from 'react-router-dom';
import { findById } from '../helpers/app';
import { checkDecimal } from '../helpers/formatters';

// Import actions
import {
  showLoading, hideLoading, setPaymentMethod, setConvenienceFee, showMessageBar, sendReceipt
} from '../actions/app';
import { 
  fetchLoanAccounts, linkLoanAccount, setSelectedLoanAccount, deleteLoanAccount 
} from '../actions/loan-account';
import { fetchPaymentCards, getTransactionMargin, getClientImages, getClientDetails } from '../actions/client';

import { requestConvenienceFee } from '../api/payment';
import { isOkHttpResponse } from '../helpers/app';

// Import components
import AccountDetails from '../components/home/account-details';
import AddPaymentMethodModal from '../components/add-payment-method-modal';
import Chat from './chat';
import LoanAccount from '../components/application/loan-account';
import Modal from '../components/application/modal';
import PaymentForm from '../components/application/loan-account/payment-form';
import PaymentMethods from '../components/application/payment-methods';
import SuccessPaymentNotice from '../components/payment/success-payment-notice';
import ClientBorrowerMessages from '../components/home/client-borrower-messages';

// Import styles
import '../styles/layout/admin-home.css';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      isAchTermsAndConditionsModalOpen: false,
      isModalOpen: false,
      modal: null,
      selectedPaymentMethod: null,
      conveniencesFee: null
    }
  }

  paymentDetails = null;

  /**
   * Fetch the initial data.
   */
  componentDidMount() {
    this.fetchInitialData();
  }

  /**
   * Fetch the loan accounts and the payment methods.
   */
  fetchInitialData = () => {
    this.props.showLoading();
    if (this.props.selectedLoanAccount !== null) {
      this.props.getClientDetails(this.props.selectedLoanAccount.loanNo);
      this.props.getClientImages(this.props.selectedLoanAccount.loanNo);
    } else {
      this.props.getClientDetails();
      this.props.getClientImages();
    }
    
    return Promise.all([
      this.props.fetchLoanAccounts(), 
      this.props.fetchPaymentCards(), 
      this.props.getTransactionMargin()
    ]).then(() => {
      this.props.hideLoading();
    }).catch(() => {
      this.props.hideLoading();
    });
  }


  /**
   * Close the modal opened.
   */
  closeModal = () => {
    this.setState({
      isModalOpen: false,
      modal: null
    });
  }

  /**
   * Show the add payment method modal.
   */
  showAddPaymentMethodModal = () => {
    this.setState({
      isModalOpen: true,
      modal: 'ADD_PAYMENT_METHOD'
    });
  }

  /**
   * Handle the event when a payment is successful.
   */
  handleSuccessPayment = (details) => {
    this.paymentDetails = details;
    this.clearPaymentMethod();
    this.props.setConvenienceFee(null);
    this.setAmount('');
    this.setState({
      isModalOpen: true,
      modal: 'SUCCESS_PAYMENT'
    });
  }

  /**
   * Close the modal to add a payment method and reload the payment methods.
   */
  handleAddPaymentMethodSuccess = () => {
    this.closeModal();
    this.fetchInitialData();
  }

  /**
   * Handle the send receipt event.
   * 
   * @param {*} id The receipt id.
   */
  handleSendPaymentReceipt = (values) => {
    this.closeModal();
    this.props.sendReceipt(values)
      .then((sended) => {
        if (sended) {
          this.fetchInitialData();
        }
      })
      .catch(() => {
        Application.showInternalError();
      });;
  }

  /**
   * Handle when a new account has linked.
   * 
   * @param id
   */
  handleLinkLoanSuccess = (id) => {
    sessionStorage.removeItem('acceptedBkUdfPopup');
    this.fetchInitialData()
      .then(() => {
        this.props.setSelectedLoanAccount(id);
      })
      .catch(() => {
        Application.showInternalError();
      });
  }

  /**
   * Handle the event when a user has deleted a loan account.
   */
  handleDeleteLoanAccountSuccess = () => {
    this.fetchInitialData()
      .then(() => {
        this.setState({
          amount: '',
          selectedPaymentMethod: null
        });

        this.props.setConvenienceFee(null);
      })
      .catch(() => {
        Application.showInternalError();
      });
  }

  /**
   * Handle when payment method changes.
   * 
   * @param key - The setted key for the payment method.
   */
  handleChangePaymentMethod = (index, added_recently = false) => {
    //If the payment method was just added, it needs to be selected automatically
    if (added_recently) {
      var self = this
      var checkLoading = setInterval(function(){
        var { loading } = self.props;
        if (loading == false) {
            let paymentMethod = self.props.cards[index];
            self.props.setPaymentMethod(paymentMethod.cardBrand, self.props.selectedLoanAccount.id, paymentMethod.paymentType, null, paymentMethod.id, this.state.amount);
            self.setState({
              selectedPaymentMethod: paymentMethod
            });
            clearInterval(checkLoading);
        }
      }, 500);
      return;
    }
    
    let paymentMethod = this.props.cards[index];
    this.props.setPaymentMethod(paymentMethod.cardBrand, this.props.selectedLoanAccount.id, paymentMethod.paymentType, null, paymentMethod.id, this.state.amount);
    this.setState({
      selectedPaymentMethod: paymentMethod
    });
  }

  /**
   * Unselected the current payment method.
   */
  clearPaymentMethod = () => {
    this.setState({
      selectedPaymentMethod: null
    });
  }

  /**
   * Handler for on change event on the amount field.
   * 
   * @param event
   */
  handleChangeAmount = (event) => {
    if (this.state.selectedPaymentMethod) {
      this.props.setPaymentMethod(this.state.selectedPaymentMethod.cardBrand, this.props.selectedLoanAccount.id, this.state.selectedPaymentMethod.paymentType, null, this.state.selectedPaymentMethod.id, event.target.value, false);
    }
    this.setAmount(event.target.value);
  }

  /**
   * Set the new value for amount.
   * 
   * @param value
   */
  setAmount = (value) => {
    value = checkDecimal(value);
    if (value) {
      this.setState({amount: value[0]});
    }
    
  }

  /**
   * render the form to schedule a payment.
   */
  renderPaymentForm() {
    return (
      <PaymentForm 
        amount={this.state.amount}
        onChangeAmount={this.handleChangeAmount}
        setAmount={this.setAmount}
        loanAccount={this.props.selectedLoanAccount}
        selectedPaymentMethod={this.state.selectedPaymentMethod}
        notifyPmtLessThanDue={this.props.client.data.notifyPmtLessThanDue}
        onSuccess={this.handleSuccessPayment}
        paymentMethods={
          <PaymentMethods 
            paymentMethods={this.props.cards}
            selected={this.state.selectedPaymentMethod}
            onChange={this.handleChangePaymentMethod}
            onAddPaymentMethod={this.showAddPaymentMethodModal}
            syncWithStore={false} />
        }
      />
    );
  }

  /**
   * Render the modal.
   */
  renderModal() {
    return (
      <Modal 
        isOpen={this.state.isModalOpen}
        onClose={this.closeModal}>
        { this.renderModalContent() }
      </Modal>
    );
  }

  /**
   * Render the indicate content for the requested modal.
   */
  renderModalContent() {
    switch (this.state.modal) {
      case 'ADD_PAYMENT_METHOD' :
        return <AddPaymentMethodModal
          onSuccess={this.handleAddPaymentMethodSuccess}
          changePayment={this.handleChangePaymentMethod} />
      case 'SUCCESS_PAYMENT' :
        return <SuccessPaymentNotice
          payment={this.paymentDetails}
          onSendReceipt={this.handleSendPaymentReceipt}
          whiteLabel={this.props.whiteLabel} />
      default :
        return null;
    }
  }

  /**
   * Render the component view.
   */
  render() {
    return (
      <div className="content app-home">
        <div className="app-home__load-account">
          {this.props.loanAccounts !== null &&
            <LoanAccount
              onAddPaymentMethodSuccess={this.fetchInitialData}
              onDeleteLoanAccountSuccess={this.handleDeleteLoanAccountSuccess}
              onLinkLoanAccountSuccess={this.handleLinkLoanSuccess}
              onUploadImageSuccess={this.fetchInitialData}
              form={this.renderPaymentForm()} 
            />
          }
        </div>

        {this.props.hideChatInBp !== '1' && 
          <div className="app-home__chat">
            <Chat  whiteLabel={this.props.whiteLabel} />
          </div>
        }

        <div className="app-home__account-details">
          <ClientBorrowerMessages />
          <AccountDetails 
            loanAccount={this.props.selectedLoanAccount}
            paidOff={this.props.hidePaidOff} 
            whiteLabel={this.props.whiteLabel}
          />
        </div>

        { this.renderModal() }
      </div>
    );
  }
}

const loanAccountService = new LoanAccountService();

const mapStateToProps = (state) => {
  let paymentMethod = findById(state.app.payment.selectedMethod, state.client.cards);

  return {
    hideChatInBp: state.client.data.hideChatInBp,
    hidePaidOff: state.client.data.hidePaidOff,
    client: state.client,
    cards: state.client.data.borAchRealtimePmts
      ? state.client.cards
      : state.client.cards.filter((itm,indx)=>{return itm.paymentType == 1}),
    colorCode: state.client.data.colorCode,
    loanAccounts: state.app.loanAccounts,
    selectedLoanAccount: loanAccountService.get(state.app.selectedLoanAccount),
    whiteLabel : state.whiteLabel,
    paymentMethod,
    loading: state.app.loading,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      linkLoanAccount, deleteLoanAccount, showLoading, hideLoading, setPaymentMethod, setConvenienceFee, 
      setSelectedLoanAccount, showMessageBar, fetchLoanAccounts, fetchPaymentCards, sendReceipt, 
      getTransactionMargin, getClientImages, getClientDetails
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
