import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './logo';
import InfoModal from './modals/info-modal';
import Modal from './modal';
import { connect } from 'react-redux';

class AppHeader extends Component {
  state = {
    isMenuOpen: false,
    isModalOpen: false,
    showContactUsModal: false
  }

  changeLanguage = (ev) => {
    if(this.props.changeLanguage) {
      this.props.changeLanguage(ev.target.value);
    }
  }


  /**
   * Handle the click on the logo for display the info.
   */
  openInfo = () => {
    this.setState({
      isMenuOpen: false,
      isModalOpen: true
    });
  }

  /**
   * Close the modal with the logo info.
   */
  closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  }

  /**
   * Handle the event when user display the navigation menu on mobile devices.
   *
   * @param {*} event
   */
  handleToggle = (e) => {
    if (this.state.isModalOpen) {
      this.closeModal();
    }

    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    });
  }

  handleLinkClick = (e) => {
    this.handleToggle();
    this.closeModal();
  }

  handleContactClick = (e) => {
    this.setState({
      showContactUsModal: true
    });
  }
  
  handleContactClose = (e) => {
    this.setState({
      showContactUsModal: false
    });
  }

  /**
   * Render the component view.
   */
  render() {
    const { color, logo, company, selectedLanguage } = this.props;
    
    // Append class to display the menu.
    let navbarClass = 'app-header__navbar';

    if (this.state.isMenuOpen) {
      navbarClass += ' app-header__navbar--open';
    }

    // Custom styles for client.
    let menuItemStyles = {
      borderBottomColor: color
    }
    let contactUsModalContent = <div>
      <div className="modal__body flex-block flex-center flex-block--vertical-centered">
        <div className="modal__body-content--pad35 flex-block">
          <div>
            <div className="app-header__logo-info-wrapper">
              <img className="app-header__logo-info-img" src={logo === undefined ? 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=' : logo} alt="" />
            </div>
            <p className="app-header__logo-info-address">
              {company.addressApptNum}<br />
              {company.addressStreet}<br />
              {`${company.addressCity}, ${company.addressState} ${company.addressZip}`}<br />
              <b>{this.props.whiteLabel.labels['Email:']} </b><a href={`mailto:${company.customerCareEmail}`}>{company.customerCareEmail}</a><br />
              <b>{this.props.whiteLabel.labels['Phone:']} </b> <a href={`tel:${company.phoneNumber}`}>{company.phoneNumber && company.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")}</a><br />
              <b>{this.props.whiteLabel.labels['Website:']} </b><a href={company.website} target="_blank">
                {company.website}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>;
    let contactUsModal = <Modal isOpen={this.state.showContactUsModal} onClose={this.handleContactClose}>
      <InfoModal type={null} content={contactUsModalContent} noButton />
    </Modal>;


    return (
      <header className="app-header">
        <div className="app-header__content">
          <Logo 
            src={logo === undefined ? 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=' : logo} 
            addressApptNum={company.addressApptNum}
            addressStreet={company.addressStreet}
            addressCity={company.addressCity}
            addressState={company.addressState}
            addressZip={company.addressZip}
            phoneNumber={company.phoneNumber}
            website={company.website}
            isModalOpen={this.state.isModalOpen}
            onOpenInfo={this.openInfo}
            onClose={this.closeModal} />

          {/**
            ** Toggle for display navbar on mobile devices
            **/}
          <div className={ navbarClass }>
            <div className="app-header__navbar-toggle" onClick={ this.handleToggle }>
              <span className="app-header__toggle-icon"></span>
              <span className="app-header__toggle-icon"></span>
              <span className="app-header__toggle-icon"></span>
            </div>

            {/**
              ** Navitation menu
              **/}
            <nav>
              <ul className="app-header__navbar-items">
                <li>
                  <NavLink 
                    to="/home" 
                    className="app-header__navbar-item" 
                    activeClassName="app-header__navbar-item--active" 
                    activeStyle={menuItemStyles}
                    onClick={this.handleLinkClick}>
                    <i className="fas fa-home"></i> {this.props.whiteLabel.labels['Home']}
                  </NavLink>
                </li>

                {this.props.client.enableConstantAi === 1 && (
                  <li>
                    <NavLink 
                      to="/servicing" 
                      className="app-header__navbar-item" 
                      activeClassName="app-header__navbar-item--active" 
                      activeStyle={menuItemStyles}
                      onClick={this.handleLinkClick}>
                      <i className="fas fa-home"></i> {this.props.whiteLabel.labels['Servicing']}
                    </NavLink>
                  </li>
                )}

                <li>
                  <NavLink 
                    to="/schedule-payment" 
                    className="app-header__navbar-item" 
                    activeClassName="app-header__navbar-item--active" 
                    activeStyle={menuItemStyles}
                    onClick={this.handleLinkClick}>
                    <i className="fas fa-calendar"></i> {this.props.whiteLabel.labels['Schedule']}
                  </NavLink>
                </li>
                
                {!this.props.client.enableConstantAi && (
                <li>
                  <NavLink 
                    to="/receipts" 
                    className="app-header__navbar-item" 
                    activeClassName="app-header__navbar-item--active" 
                    activeStyle={menuItemStyles}
                    onClick={this.handleLinkClick}>
                    <i className="fas fa-receipt"></i> {this.props.whiteLabel.labels['Receipts']}
                  </NavLink>
                </li>
                )}
                
                {!this.props.hideBpContactUs && (
                <li>
                  <a 
                    href="#"
                    className="app-header__navbar-item" 
                    onClick={this.handleContactClick}>
                    <i className="fas fa-address-card"></i> {this.props.whiteLabel.labels['Contact Us']}
                  </a>
                </li>
                )}

                {this.props.statements && (
                <li>
                  <NavLink 
                    to="/statements" 
                    className="app-header__navbar-item" 
                    activeClassName="app-header__navbar-item--active" 
                    activeStyle={menuItemStyles}
                    onClick={this.handleLinkClick}>
                    <i className="fas fa-file"></i> {this.props.whiteLabel.labels['Statements']}
                  </NavLink>
                </li>
                )}

                <li>
                  <NavLink 
                    to="/settings" 
                    className="app-header__navbar-item" 
                    activeClassName="app-header__navbar-item--active" 
                    activeStyle={menuItemStyles}
                    onClick={this.handleLinkClick}>
                    <i className="fas fa-cog"></i> {this.props.whiteLabel.labels['Settings']}
                  </NavLink>
                </li>
              </ul>
            </nav>

            {/**
              ** Logout button
              **/}
            <div className="app-header__navbar-logout">
              <span className="app-header__navbar-item" onClick={this.props.onLogout}>
                <i className="fas fa-power-off"></i> {this.props.whiteLabel.labels['Logout']}
              </span>
            </div>
          </div>
        </div>
        {contactUsModal}
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client.data,
    whiteLabel: state.whiteLabel
  };
};
export default connect(mapStateToProps) (AppHeader);
